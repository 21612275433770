
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from "@tinymce/tinymce-vue";
import TranslationWrapper from '@/layouts/components/TranslationWrapper.vue'
import TranslationUtil from '@/utils/TranslationUtil'

export default {

  components: {
    Multiselect,
    TranslationWrapper,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      translations: {},
      favoursSnippet: snippets ?? {},
      favoursList: [],
      paginatedFavoursList: [],
      paginatedSearchedFavoursList: [],
      favoursPaginatedList: [],
      rolesList: [],
      favoursListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      addOrEditSpace: '',
      newType: '',
      currentSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        favourId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      defaultSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        favourId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      dialog: false,
      spaceDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogDeletePhoto: false,
      dialogCarousel: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      spaceSubmitted: false,
      mobileNavBar: false,
      enterNewType: false,
      favourId: this.$route.params.id,
      editedIndex: -1,
      toDeleteSpace: {},
      editedItem: {
        id: '',
        name: '',
        description: '',
        type: '',
        brand: '',
        floors: [],
        facilities: [],
        photos: [],
        photoIds: []
      },
      defaultItem: {
        id: '',
        name: '',
        description: '',
        type: '',
        brand: '',
        floors: [],
        facilities: [],
        photos: [],
        photoIds: []
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: '',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldFavour: {},
      addressesDataList: [],
      showSpaces: [],
      favourSpaces: [],
      favourServices: [],
      facilities: [],
      types: [],
      selectedTypeId: '',
      selectedFacilities: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    headers() {
      return [
        { text: this.favoursSnippet.id, value: 'id', width: '5%' },
        { text: this.favoursSnippet.name, value: 'name', width: '15%' },
        { text: this.favoursSnippet.description, value: 'description', width: '15%' },
        { text: this.favoursSnippet.isEventCapable, value: 'isEventCapable', width: '10%' },
        { text: this.favoursSnippet.isInvitational, value: 'isInvitational', width: '10%' },
        { text: this.favoursSnippet.isBookable, value: 'isBookable', width: '10%' },
        {
          text: this.favoursSnippet.maxNumberOfParticipants,
          value: 'maxNumberOfParticipants',
          width: '10%'
        },
        { text: this.favoursSnippet.mapPosition, value: 'mapPosition', width: '10%' },
        { text: this.favoursSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },
    dialogDeletePhoto( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );

    DataEmitterService.$emit( 'route-change' );

    StorageService.deleteFavourFromStorage();
    next()
  },

  async mounted() {
    await this.getFacilitiesApi();
    await this.getFavourApi();

    this.loading = false;
  },

  methods: {

    openSpaceDetails( service ) {
      if ( service && service.id ) {
        let resolvedLocation = this.$router.resolve( {
          name: `service`,
          params: { serviceId: service.id }
        } )?.href;
        // StorageService.setFavourToStorage( this.editedItem );
        console.log( 'resolvedLocation', resolvedLocation )
        this.$router.push( resolvedLocation );
      }
    },

    updateMainTranslations() {
      if (this.translations['en']) {
        this.translations['en'].name = this.editedItem.name;
        this.translations['en'].description = this.editedItem.description;
      }
    },

    updateTranslations(data) {
      this.translations = data;
      this.editedItem = {
        ...this.editedItem,
        ...this.translations['en'],
      }
    },

    setTranslations() {
      this.translations = TranslationUtil.transformToRequestDto(
        this.editedItem.translations,
        {
          description: this.editedItem.description,
          name: this.editedItem.name,
        }
      );
    },

    async getFavourApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/facility-favours/${ this.favourId }` );
      this.setTranslations();

      StorageService.setFavourToStorage( this.editedItem );
      DataEmitterService.$emit( 'route-change' );

      this.oldFavour = this.getObjClone( this.editedItem );
      this.editedItem.facilities.forEach( item => {
        this.facilities.map( fa => {
          if ( fa.id === item.id ) {
            fa.selected = true
          }
        } );
        this.selectOrUnselectFacility( item.id )
      } );
    },


    async getFacilitiesApi() {
      this.facilities = await ApiRequestService.getRequest( `api/admin-facilities/list` );
      this.facilities.forEach( item => item.selected = false );
    },

    selectType( type ) {
      let exists = this.types?.find( item => item.name === type );
      let newType = {
        name: type,
        selected: true
      }
      this.editedItem.type = exists ?? newType;
      if ( !exists ) {
        this.types.push( newType )
        this.selectedTypeId = type;
        this.newType = '';

      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    selectOrUnselectFacility( id ) {
      if ( this.selectedFacilities.indexOf( id ) === -1 ) {
        this.selectedFacilities.push( id );
      } else {
        this.selectedFacilities = this.selectedFacilities.filter( item => item !== id );
      }
    },

    async getChangedFavourData( oldFavour ) {
      const dataFormUpdate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldFavour[key] === this.editedItem[key] ) {
          continue
        }

        if ( key === 'facilities' ) {
          let readyFacilities = [];
          for ( let [ facilityKey, facilityValue ] of Object.entries( this.selectedFacilities ) ) {
            readyFacilities.push( facilityValue );
          }
          dataFormUpdate[key] = readyFacilities;
          continue;
        }

        if (key === 'translations') {
          dataFormUpdate[key] = this.translations;
          continue;
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      return dataFormUpdate
    },


    async updateFavourRequest( oldFavour ) {
      let existsPhotosCount = this.editedItem?.photos?.length;
      if ( this.editedItem?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedFavourData( oldFavour );
      const updatedFavour = await ApiRequestService.updateRequest( `api/facility-favours/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedFavour.errorMessage ) {
        return
      }

      if ( updatedFavour ) {
        this.editedItem = updatedFavour;
        this.setTranslations();
      }
    },

    dataIsValid() {
      const nameIsValid = this.favourIsValid( this.editedItem?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },

    favourIsValid( v ) {
      return ( v && v.length > 0 ) || this.favoursSnippet.required
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    async updateFavour() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        this.globalError = isValid;
        console.log( 'this.globalError', this.globalError )
        return;
      }

      await this.updateFavourRequest( this.oldFavour );
    },

    async save() {
      this.submitted = true;
      await this.updateFavour();
    },
  }
}

